.page_form,
.page_index {
  @include minSM {
    .header:not(.header--fixed) {
      box-shadow: none;
      background-color: transparent;

      .logo__txt {
        color: #fff;
      }
    }
    .content {
      padding-top: 0;
    }
  }
}

body:not(.page_index) {
  .content {
    display: flex;
    flex-direction: column;

    & > .section {
      flex: 1 1 auto;
    }
  }
}
