html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  line-height: 1;
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
  font-family: inherit;
  color: inherit;
}

.container {
  max-width: 1170px;
  padding-right: 15px;
  padding-left: 15px;
}

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1024px) and (max-width: 1259px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}

@media (min-width: 1024px) and (max-width: 1259px) {
  .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 1024px) and (max-width: 1259px) {
  .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 1024px) and (max-width: 1259px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1260px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}

@media (min-width: 1260px) {
  .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1260px) {
  .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1260px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 1024px) and (max-width: 1259px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1260px) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}

.container {
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.col,
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-11 {
  width: 91.66666667%;
}

.col-xs-10 {
  width: 83.33333333%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.66666667%;
}

.col-xs-7 {
  width: 58.33333333%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.66666667%;
}

.col-xs-4 {
  width: 33.33333333%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.66666667%;
}

.col-xs-1 {
  width: 8.33333333%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-pull-11 {
  right: 91.66666667%;
}

.col-xs-pull-10 {
  right: 83.33333333%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-8 {
  right: 66.66666667%;
}

.col-xs-pull-7 {
  right: 58.33333333%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-5 {
  right: 41.66666667%;
}

.col-xs-pull-4 {
  right: 33.33333333%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-2 {
  right: 16.66666667%;
}

.col-xs-pull-1 {
  right: 8.33333333%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-push-11 {
  left: 91.66666667%;
}

.col-xs-push-10 {
  left: 83.33333333%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-8 {
  left: 66.66666667%;
}

.col-xs-push-7 {
  left: 58.33333333%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-5 {
  left: 41.66666667%;
}

.col-xs-push-4 {
  left: 33.33333333%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-2 {
  left: 16.66666667%;
}

.col-xs-push-1 {
  left: 8.33333333%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

@media (min-width: 768px) {
  .col,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 1024px) {
  .col,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 1260px) {
  .col,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}

.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  content: " ";
  display: table;
}

.container:after,
.container-fluid:after,
.row:after {
  clear: both;
}

.wysiwyg {
  color: #797979;
}

.wysiwyg *:last-child {
  margin-bottom: 0;
}

.wysiwyg h2, .wysiwyg .h2,
.wysiwyg h3, .wysiwyg .h3,
.wysiwyg h4, .wysiwyg .h4,
.wysiwyg p {
  margin-bottom: 15px;
}

.wysiwyg h1, .wysiwyg .h1,
.wysiwyg h2, .wysiwyg .h2,
.wysiwyg h3, .wysiwyg .h3,
.wysiwyg h4, .wysiwyg .h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #05326a;
}

.wysiwyg h1, .wysiwyg .h1 {
  padding-bottom: 10px;
  margin-bottom: 30px;
  border-bottom: 2px solid #07a049;
  font-size: 30px;
}

@media (max-width: 768px) {
  .wysiwyg h1, .wysiwyg .h1 {
    text-align: center;
  }
}

.wysiwyg h2, .wysiwyg .h2 {
  font-size: 20px;
}

.wysiwyg h3, .wysiwyg .h3 {
  font-size: 18px;
}

.wysiwyg h4, .wysiwyg .h4 {
  font-size: 12px;
  font-weight: 700;
}

.wysiwyg p, .wysiwyg ul, .wysiwyg ol {
  font-size: 16px;
}

.wysiwyg ul, .wysiwyg ol {
  margin-bottom: 20px;
}

.wysiwyg ul {
  list-style: inside disc;
}

.wysiwyg ol {
  list-style: inside decimal;
}

.wysiwyg li {
  margin-bottom: 15px;
}

.wysiwyg a {
  color: #607890;
}

.wysiwyg a:hover {
  color: #036;
}

.wysiwyg a[href="#"] {
  color: currentColor;
  cursor: default;
}

.wysiwyg table {
  margin-bottom: 15px;
  font-size: 14px;
}

.wysiwyg th span, .wysiwyg td span {
  display: inline;
  word-break: break-all;
}

.wysiwyg th {
  padding: 10px;
  border: 1px solid #e9ffff;
  background-color: #0585cb;
  color: #e9ffff;
  text-align: left;
  text-transform: uppercase;
  vertical-align: top;
}

@media (max-width: 768px) {
  .wysiwyg th {
    padding: 5px;
  }
}

.wysiwyg td {
  padding: 5px;
  border: 1px solid #ccc;
  vertical-align: top;
  border-spacing: 0;
  background-color: #fff;
}

body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  line-height: 1.4;
}

.page_wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100vh;
}

.content {
  flex: 1 1 auto;
  padding-top: 71px;
  background-color: #f6f6f6;
}

@media (max-width: 768px) {
  .content {
    padding-top: 61px;
  }
  .content__side {
    margin-bottom: 20px;
  }
}

.section {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (max-width: 768px) {
  .section {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .row--reverse {
    display: flex;
    flex-direction: column-reverse;
  }
}

.icon {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
}

.icon svg {
  background: inherit;
  height: 100%;
  width: 100%;
  fill: inherit;
  vertical-align: top;
}

.index_info {
  background-image: url("../images/features_bg.jpg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  text-align: center;
}

.index_info__text {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
}

.index_info__text .h3, .index_info__text h3 {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
  color: #080808;
}

.index_info__item:not(:last-child) {
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .index_info__item:not(:last-child) {
    margin-bottom: 20px;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #fff;
  transition: 0.3s;
}

@media (max-width: 768px) {
  .header {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.header--fixed {
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
}

@media (min-width: 768px) {
  .header--fixed {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: block;
  text-decoration: none;
}

.logo__txt {
  display: inline-block;
  font-size: 24px;
  line-height: 41px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.footer {
  font-size: 14px;
  background-color: #f6f6f6;
}

.footer__disclosures {
  padding-top: 20px;
  padding-bottom: 20px;
  color: #757575;
  border-bottom: 1px solid #757575;
}

.footer__disclosures .wysiwyg p {
  font-size: inherit;
}

.footer__copyright {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}

@media (max-width: 768px) {
  .footer .container {
    display: flex;
    flex-direction: column;
  }
  .footer__disclosures {
    order: -1;
  }
}

.menu {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  border-bottom: 1px solid #767575;
}

.menu__item {
  display: inline-block;
}

@media (max-width: 768px) {
  .menu__item {
    display: block;
  }
  .menu__item + .menu__item {
    border-top: 1px solid #00851c;
  }
}

.menu__link {
  display: inline-block;
  padding: 6px 15px;
  font-size: 16px;
  line-height: 1.5;
  color: #1b1b1b;
  text-decoration: none;
}

.menu__link:hover, .menu__link--active {
  color: #00851c;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .menu__link {
    display: block;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.form_holder {
  position: relative;
  background-image: url("../images/form_bg.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-color: #596670;
}

.form_holder:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.form_holder .container {
  width: 100%;
}

@media (min-width: 768px) {
  .form_holder {
    display: flex;
    align-items: center;
    min-height: calc(100vh - 139px);
  }
}

.form_holder_title_holder {
  color: #fff;
  text-align: center;
}

.form_holder__title {
  margin: 0 auto 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 72px;
  line-height: 0.85;
  text-shadow: 0 2px 9px rgba(0, 0, 0, 0.69);
}

@media (max-width: 768px) {
  .form_holder__title {
    font-size: 32px;
  }
}

.form_holder__sub_title {
  display: inline-block;
  margin-bottom: 10px;
  padding: 4px;
  font-size: 21px;
  font-weight: 300;
  line-height: 1.25;
  background-color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .form_holder__sub_title {
    margin-bottom: 6px;
  }
}

.form_holder__sub_title:last-child {
  margin-bottom: 15px;
}

.benefits {
  background-color: #274150;
  border-bottom: 5px solid #1a313e;
  box-shadow: 0 0 0 #aa9b93;
  font-family: "Montserrat", sans-serif;
}

.benefits_list .icon {
  display: block;
  width: 65px;
  height: 65px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 9px;
  fill: #4a809f;
}

.benefits_list .icon-laptop {
  width: 80px;
  height: 56px;
}

@media (min-width: 768px) {
  .benefits_list {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

@media (max-width: 768px) {
  .benefits_list {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.benefits_list__item {
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  color: #fff;
}

@media (min-width: 768px) {
  .benefits_list__item {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .benefits_list__item {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .benefits_list__item:not(:last-of-type) {
    margin-bottom: 25px;
  }
}

.form__header {
  padding: 20px 15px 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 21px;
  color: #fff;
  text-align: center;
  background-color: #000;
}

.form__body {
  padding: 15px;
}

.form__footer {
  padding-bottom: 20px;
}

.form__row:not(:last-child) {
  margin-bottom: 15px;
}

.form__text a {
  color: #99ca3c;
  text-decoration: underline;
}

.form__text a:hover {
  text-decoration: none;
}

.form .btn {
  width: 100%;
}

.form .comodo_logo {
  display: flex;
  width: 200px;
  height: 50px;
  margin-right: auto;
  margin-left: auto;
  text-decoration: none;
}

.form .comodo_logo__img {
  flex-shrink: 0;
  vertical-align: middle;
}

.form .comodo_logo:before {
  content: "SSL Secure Connection";
  display: block;
  width: 75px;
  margin-top: 10px;
  margin-right: 10px;
  flex-shrink: 0;
  font-size: 13px;
  font-weight: 700;
  color: #79c400;
}

.form label.error {
  font-size: 11px;
  color: #f20;
}

.form--short {
  border-radius: 15px;
  background-color: #fff;
  border: 1px solid #d1d1d1;
  box-shadow: 0 0 5px #b1b1b1;
  overflow: hidden;
}

.input,
.select {
  width: 100%;
  height: 46px;
  padding-left: 10px;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  background-color: #fbfbfb;
  outline: none;
  font-size: 18px;
  color: #444;
}

.input:focus,
.select:focus {
  border-color: #05326a;
}

.input::placeholder,
.select::placeholder {
  color: #444;
}

.input {
  padding-right: 10px;
}

.input.error {
  border-color: #f20;
}

.select {
  padding-right: 30px;
  appearance: none;
  background-image: url("../images/arrow-select.svg");
  background-repeat: no-repeat;
  background-position: center right 6px;
  background-size: 14px 8px;
}

.select::-ms-expand {
  display: none;
}

.btn {
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #00851c;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s ease;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  text-decoration: none;
}

.btn:active {
  background-color: #000;
}

.btn:hover {
  background-color: #00390c;
  text-decoration: none;
}

.btn:focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.3);
}

.label__text {
  display: inline-block;
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 13px;
  color: #949494;
}

.message {
  display: none;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.25;
}

.message--success {
  color: green;
}

.message--error {
  color: #f20;
}

.unsubscribe .form--unsubscribe {
  margin-bottom: 30px;
  padding: 20px;
  background-color: #fff;
  border-radius: 7px;
}

.unsubscribe .btn {
  font-size: 23px;
}

.unsubscribe .form__row {
  margin-bottom: 15px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.3s ease;
}

.overlay__container {
  position: absolute;
  top: 50px;
  left: 5px;
  right: 5px;
  bottom: 50px;
  z-index: 1;
}

.overlay__content {
  border-radius: 5px;
  padding: 10px 30px;
  max-width: 1000px;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
}

.overlay__close {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
  display: block;
  width: 30px;
  height: 30px;
  color: #fff;
  cursor: pointer;
}

.overlay__close:before, .overlay__close:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 20px;
  height: 2px;
  background-color: currentColor;
}

.overlay__close:before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.overlay__close:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

@media (max-width: 768px) {
  .overlay__container {
    left: 0;
    right: 0;
    bottom: 0;
  }
  .overlay__content {
    padding: 10px;
    border-radius: 0;
  }
  .overlay__close, .overlay__close:before {
    width: auto;
    top: 0;
    left: 0;
    right: 0;
    transform: none;
    background-color: transparent;
  }
  .overlay__close {
    height: 50px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.8);
    color: #000;
  }
  .overlay__close:before {
    content: 'Tap to Close';
    height: auto;
    font-size: 24px;
    line-height: 50px;
    color: #fff;
  }
  .overlay__close:after {
    display: none;
  }
}

html.js_popup_is_open, html.js_popup_is_open body {
  position: relative;
  height: 100%;
  overflow: hidden;
}

@media (min-width: 768px) {
  .page_form .header:not(.header--fixed),
  .page_index .header:not(.header--fixed) {
    box-shadow: none;
    background-color: transparent;
  }
  .page_form .header:not(.header--fixed) .logo__txt,
  .page_index .header:not(.header--fixed) .logo__txt {
    color: #fff;
  }
  .page_form .content,
  .page_index .content {
    padding-top: 0;
  }
}

body:not(.page_index) .content {
  display: flex;
  flex-direction: column;
}

body:not(.page_index) .content > .section {
  flex: 1 1 auto;
}
