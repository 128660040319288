.form_holder {
  position: relative;

  background-image: url("../images/form_bg.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-color: #596670;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#000, 0.5);
  }

  .container {
    width: 100%;
  }

  @include minSM {
    display: flex;
    align-items: center;
    min-height: calc(100vh - #{$benefitsHeight});
  }
}

.form_holder_title_holder {
  color: #fff;
  text-align: center;
}

.form_holder__title {
  margin: 0 auto 10px;

  font-family: $secondaryFont;
  font-weight: 700;
  font-size: 72px;
  line-height: 0.85;

  text-shadow: 0 2px 9px rgba(0, 0, 0, 0.69);

  @include maxSM {
    font-size: 32px;
  }
}

.form_holder__sub_title {
  display: inline-block;
  margin-bottom: 10px;
  padding: 4px;

  font-size: 21px;
  font-weight: 300;
  line-height: 1.25;

  background-color: rgba(#000, 0.5);

  @include maxSM {
    margin-bottom: 6px;
  }

  &:last-child {
    margin-bottom: 15px;
  }
}
