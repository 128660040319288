.unsubscribe {
  .form--unsubscribe {
    margin-bottom: 30px;
    padding: 20px;
    background-color: #fff;
    border-radius: 7px;
  }

  .btn {
    font-size: 23px;
  }

  .form__row {
    margin-bottom: 15px;
  }
}
