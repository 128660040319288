.menu {
  padding-top: 20px;
  padding-bottom: 20px;

  text-align: center;

  border-bottom: 1px solid #767575;
}

.menu__item {
  display: inline-block;

  @include maxSM {
    display: block;

    & + & {
      border-top: 1px solid #00851c;
    }
  }
}

.menu__link {
  display: inline-block;
  padding: 6px 15px;

  font-size: 16px;
  line-height: 1.5;
  color: #1b1b1b;

  text-decoration: none;

  &:hover, &--active {
    color: #00851c;
    text-decoration: underline;
  }

  @include maxSM {
    display: block;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
