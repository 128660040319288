body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  color: #000;
  font-family: $primaryFont;
  font-size: 12px;
  line-height: 1.4;
}

.page_wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100vh;
}

.content {
  flex: 1 1 auto;
  padding-top: $headerHeight;

  background-color: #f6f6f6;

  @include maxSM {
    padding-top: $headerHeightMobile;

    &__side {
      margin-bottom: 20px;
    }
  }
}

.section {
  padding-top: 60px;
  padding-bottom: 60px;

  @include maxSM {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.row--reverse {
  @include maxSM {
    display: flex;
    flex-direction: column-reverse;
  }
}

.icon {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;

  svg {
    background: inherit;
    height: 100%;
    width: 100%;
    fill: inherit;
    vertical-align: top;
  }
}

.index_info {
  background-image: url("../images/features_bg.jpg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  text-align: center;
}

.index_info__text {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;

  .h3, h3 {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 700;
    color: #080808;
  }
}

.index_info__item {
  &:not(:last-child) {
    margin-bottom: 40px;
    @include maxSM {
      margin-bottom: 20px;
    }
  }
}
