.wysiwyg {
  color: #797979;

  *:last-child {
    margin-bottom: 0;
  }

  h2, .h2,
  h3, .h3,
  h4, .h4,
  p {
    margin-bottom: 15px;
  }

  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4 {
    font-family: $secondaryFont;
    font-weight: 700;
    color: #05326a;
  }

  h1, .h1 {
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: 2px solid #07a049;
    font-size: 30px;

    @include maxSM {
      text-align: center;
    }
  }

  h2, .h2 {
    font-size: 20px;
  }

  h3, .h3 {
    font-size: 18px;
  }

  h4, .h4 {
    font-size: 12px;
    font-weight: 700;
  }

  p, ul, ol {
    font-size: 16px;
  }

  ul, ol {
    margin-bottom: 20px;
  }

  ul {
    list-style: inside disc;
  }

  ol {
    list-style: inside decimal;
  }

  li {
    margin-bottom: 15px;
  }

  a {
    color: #607890;

    &:hover {
      color: #036;
    }

    &[href="#"] {
      color: currentColor;
      cursor: default;
    }
  }

  table {
    margin-bottom: 15px;
    font-size: 14px;
  }

  th, td {
    span {
      display: inline;
      word-break: break-all;
    }
  }

  th {
    padding: 10px;
    border: 1px solid #e9ffff;
    background-color: #0585cb;
    color: #e9ffff;
    text-align: left;
    text-transform: uppercase;
    vertical-align: top;
    @include maxSM {
      padding: 5px;
    }
  }

  td {
    padding: 5px;
    border: 1px solid #ccc;
    vertical-align: top;
    border-spacing: 0;
    background-color: #fff;
  }
}
