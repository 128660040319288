.form {
  &__header {
    padding: 20px 15px 15px;

    font-family: $secondaryFont;
    font-weight: 700;
    font-size: 21px;
    color: #fff;
    text-align: center;
    background-color: #000;
  }

  &__body {
    padding: 15px;
  }

  &__footer {
    padding-bottom: 20px;
  }

  &__row {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__text {
    a {
      color: #99ca3c;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .btn {
    width: 100%;
  }

  .comodo_logo {
    display: flex;
    width: 200px;
    height: 50px;
    margin-right: auto;
    margin-left: auto;
    text-decoration: none;

    &__img {
      flex-shrink: 0;
      vertical-align: middle;
    }

    &:before {
      content: "SSL Secure Connection";
      display: block;
      width: 75px;
      margin-top: 10px;
      margin-right: 10px;
      flex-shrink: 0;
      font-size: 13px;
      font-weight: 700;
      color: #79c400;
    }
  }

  label.error {
    font-size: 11px;
    color: #f20;
  }

  &--short {
    border-radius: 15px;
    background-color: #fff;
    border: 1px solid #d1d1d1;
    box-shadow: 0 0 5px #b1b1b1;
    overflow: hidden;
  }
}

.input,
.select {
  width: 100%;
  height: 46px;
  padding-left: 10px;

  border-radius: 5px;
  border: 1px solid #e5e5e5;
  background-color: #fbfbfb;
  outline: none;

  font-size: 18px;
  color: #444;

  &:focus {
    border-color: #05326a;
  }

  &::placeholder {
    color: #444;
  }
}

.input {
  padding-right: 10px;

  &.error {
    border-color: #f20;
  }
}

.select {
  padding-right: 30px;

  appearance: none;

  background-image: url("../images/arrow-select.svg");
  background-repeat: no-repeat;
  background-position: center right 6px;
  background-size: 14px 8px;

  &::-ms-expand {
    display: none;
  }
}

.btn {
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #00851c;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s ease;

  font-size: 30px;
  font-weight: 700;
  text-align: center;
  color: #fff;

  text-decoration: none;

  &:active {
    background-color: #000;
  }

  &:hover {
    background-color: #00390c;
    text-decoration: none;
  }

  &:focus {
    box-shadow: inset 0 0 0 2px rgba(#000, 0.3);
  }
}

.label__text {
  display: inline-block;
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 13px;
  color: #949494;
}

.message {
  display: none;
  margin-top: 20px;
  margin-bottom: 20px;

  font-size: 16px;
  line-height: 1.25;

  &--success {
    color: green;
  }

  &--error {
    color: #f20;
  }
}
