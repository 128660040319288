.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #fff;
  transition: 0.3s;

  @include maxSM {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &--fixed {
    box-shadow: 1px 1px 1px 1px rgba(#000, 0.2);

    @include minSM {
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
}

.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  display: block;
  text-decoration: none;

  &__txt {
    display: inline-block;
    font-size: 24px;
    line-height: 41px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
}
