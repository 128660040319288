.benefits {
  background-color: #274150;
  border-bottom: 5px solid #1a313e;
  box-shadow: 0 0 0 #aa9b93;
  font-family: $secondaryFont;
}

.benefits_list {
  .icon {
    display: block;
    width: 65px;
    height: 65px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 9px;

    fill: #4a809f;
  }

  .icon-laptop {
    width: 80px;
    height: 56px;
  }

  @include minSM {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  @include maxSM {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.benefits_list__item {
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  color: #fff;

  @include minSM {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  @include onlyMD {
    font-size: 18px;
  }
  @include maxSM {
    &:not(:last-of-type) {
      margin-bottom: 25px;
    }
  }
}
