.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(#000, 0.4);
  transition: 0.3s ease;

  &__container {
    position: absolute;
    top: 50px;
    left: 5px;
    right: 5px;
    bottom: 50px;
    z-index: 1;
  }

  &__content {
    border-radius: 5px;
    padding: 10px 30px;
    max-width: 1000px;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #fff;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 10;
    display: block;
    width: 30px;
    height: 30px;
    color: #fff;
    cursor: pointer;

    &:before, &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 20px;
      height: 2px;
      background-color: currentColor;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  @include maxSM {
    &__container {
      left: 0;
      right: 0;
      bottom: 0;
    }
    &__content {
      padding: 10px;
      border-radius: 0;
    }
    &__close, &__close:before {
      width: auto;
      top: 0;
      left: 0;
      right: 0;
      transform: none;
      background-color: transparent;
    }
    &__close {
      height: 50px;
      text-align: center;
      background-color: rgba(#000, 0.8);
      color: #000;

      &:before {
        content: 'Tap to Close';
        height: auto;
        font-size: 24px;
        line-height: 50px;
        color: #fff;
      }

      &:after {
        display: none;
      }
    }
  }
}

html.js_popup_is_open {
  &, body {
    position: relative;
    height: 100%;
    overflow: hidden;
  }
}
